export const mainColor = '#642ad6';

export const black = '#000';
export const bgColorFFF = '#fff';
export const bgColor222 = '#222222';
export const bgColor2d = '#2d2d2d';

export const textColorTheme = '#642ad6';
export const textColorMain = '#222222';
export const textColorLight = '#aaaaaa';
export const textColorFFF = '#fff';
export const textColorPink = '#FC5181';
export const textLightPurple = '#A27FE6';
export const textColorGrey = '#D8D8D8';
export const textColorYellow = '#FEC52D';
export const textLightYellow = '#ffd739';
export const textLightGrey = '#B8B8B8';

export const colorType1 = '#642ad6';
export const colorType2 = '#09c0d7';
export const colorType3 = '#fc5181';
export const colorType4 = '#33D69F';
export const colorType5 = '#222222';
export const colorType6 = '#FFA617';
export const colorType7 = '#EAEAEA';
export const colorType8 = '#4bd0e1';
export const colorType9 = '#8743FF';

export const kakaoColor = '#FFC300';

export const boxShadowType1 = '0px 0px 3px rgba(130, 130, 130, 0.2)';
export const boxShadowType2 = '0px 0px 3px rgba(130, 130, 130, 0.3)';
export const boxShadowType3 = '5px 0px 10px rgba(100, 100, 100, 0.1)';

export const fontSize_56 = '56px;';
export const fontSizeXXXXL = '48px;';
export const fontSize_46 = '46px;';
export const fontSize_42 = '42px;';
export const fontSize_40 = '40px;';

export const fontSize_36 = '36px;';
export const fontSize_32 = '32px;';
export const fontSizeXXXL = '30px;';
export const fontSizeXXL = '25px;';
export const fontSizeXL = '22px';
export const fontSize_20 = '20px';
export const fontSizeL = '18px';
export const fontSize_16 = '16px';
export const fontSizeM = '14px';
export const fontSize_13 = '13px';
export const fontSizeS = '12px';
export const fontSizeXS = '10px';
